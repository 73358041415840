.relative {
  position: relative;
}
.padded-v {
  padding: 1rem 10px;
}
.padded-v-big {
  padding: 2rem 10px;
}

.padded {
  padding: 1rem 2rem;
}
.b-l {
  border-left: 1px solid $border-color;
}
.b-r {
  border-right: 1px solid $border-color;
}
.b-t {
  border-top: 1px solid $border-color;
}
.b-b {
  border-bottom: 1px solid $border-color;
}

.m-t {
  margin-top: 1rem;
}
.m-b {
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .padded-lg {
    padding: 1rem 2rem;
  }
  .b-l-lg {
    border-left: 1px solid $border-color;
  }
  .b-r-lg {
    border-right: 1px solid $border-color;
  }
  .b-t-lg {
    border-top: 1px solid $border-color;
  }
  .b-b-lg {
    border-bottom: 1px solid $border-color;
  }
}

@media (min-width: 1200px) {
  .padded-xl {
    padding: 1rem 2rem;
  }
  .b-l-xl {
    border-left: 1px solid $border-color;
  }
  .b-r-xl {
    border-right: 1px solid $border-color;
  }
  .b-t-xl {
    border-top: 1px solid $border-color;
  }
  .b-b-xl {
    border-bottom: 1px solid $border-color;
  }
}

.example-column {
  padding: 12px 15px;
  background: #EEF5FF;
  border: 1px solid #AEC8FF;
  margin-bottom: 15px;
  text-align: center;
  & + .example-column {
    border-left: none;
  }
}
.example-content .progress + .progress {
  margin-top: 1rem;
}


.with-avatar {
  margin-right: 5px;
  img {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50px;
    width: 30px;
    height: auto;
    margin-right: 10px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
}
a.with-avatar {
  span {
    border-bottom: 1px solid $link-color;
  }
}

.add-agent-btn {
  i {
    margin-right: 15px;
    padding-left: 5px;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid $primary;
  }
}


.centered-load-more-link {
  display: block;
  padding: 10px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
  i {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    margin-right: 5px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid $link-color;
  }
  &:hover {
    span {
      border-bottom-color: darken($link-color, 10%);
    }
  }
  &.smaller {
    font-size: $font-size-sm;
  }
}

.text-faded {
  color: $color-text-faded;
}
.text-bright {
  color: #1D1F28;
}